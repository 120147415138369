import axios from 'axios';
import md5 from 'js-md5';
import { getLanguage, guid } from "@/utils/index";
import store from "../store/index";
import Aes from "@/utils/aes.js";
import { Toast } from "vant";
import router from "../router";
// 创建axios实例
const service = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  baseURL: process.env.NODE_ENV === 'production' ? 'https://gateway.mm-live.online' : '/commonApi', // api 的 base_url
  timeout: 50000, // 请求超时时间
  withCredentials: true
})

// request拦截器
service.interceptors.request.use(
  config => {
    let timer = new Date().getTime(), uid = localStorage.getItem('uid') || guid();
    config.headers['OS'] = "0";
    config.headers['X-Language'] = getLanguage(store.state.supportLanguage);
    config.headers['Accept-Language'] = getLanguage(store.state.supportLanguage);
    config.headers['X-AppVersion'] = `2.5.0`;
    config.headers['P-G'] = `N`;
    config.headers['appId'] = `MMLive`;
    config.headers['X-UDID'] = uid;
    config.headers['X-U'] = uid;
    config.headers['X-Timestamp'] = timer;
    config.headers['X-Sign'] = md5(`${uid}jgyh,kasd${timer}`);
    config.headers['N-L'] = "Y";

    if (sessionStorage.getItem('token')) {
      config.headers['Authorization'] = `HSBox ${sessionStorage.getItem('token')}`;
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const code = response.status;
    if (String(code) !== '200') {
      return Promise.reject('error');
    } else {
      if (response.config.url== '/config-client/base/baseInfo/encrypt?') {
        response.data = Aes.decryptLast(response.data);
      }
      return response.data;
    }
  },
  error => {
    console.log(error);
    if (error.config.url != '/config-client/base/baseInfo/encrypt?') {
      let code = (error.response.data && error.response.data.code) || 0;
      let msg = (error.response.data && error.response.data.msg) || (error.response.data && error.response.data.desc) || "";
      if (code == 991 || code == 992 || code == 993 || code == 1040 || code == 424) {
        router.replace('/login');
        store.dispatch("loginOutTm");
      }
  
      if (code == 1040 || code == 424) {
        if(msg) store.commit('setState', { loginOutInfo: msg, isLoginOutIm: true });
      } else {
        if (msg) {
          Toast(msg);
        }
      }

      if (msg) {
        Toast(msg);
      }
    }
    

    return Promise.reject(error)
  }
)
export default service

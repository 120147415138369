import request from "../utils/request";

/** 
   注册 
 */
// 手机号是否注册
export const phoneIsRegiste = (params = {}) => {
    return request.post(`/center-client/sys/user/phone/isRegiste`, {
        ...params
    });
};

//随机昵称
export const randomNickname = (params = {}) => {
    return request.post(`/center-client/sys/auth/random/nickname`, {
        ...params
    });
};

//获取行为图像验证码（验证方式+语言）
export const userCaptcha = (params = {}) => {
    return request.post(`/center-client/sys/user/captcha`, {
        ...params
    });
};

// 手机发送短信验证码  
export const sendVcoderegister = (params = {}) => {
    return request.post(`/center-client/sys/auth/send/vcode`, {
        ...params
    });
};

// 手机号注册-验证码校验
export const codeValidate = (params = {}) => {
    return request.post(`/center-client/sys/auth/phone/reg/codeValidate`, {
        ...params
    });
};

// 完善信息
export const phoneRegInfo = (params = {}) => {
    return request.post(`/center-client/sys/auth/phone/reg/info`, {
        ...params
    });
};

// 邮箱注册完善信息
export const emailRegInfo = (params = {}) => {
    return request.post(`/center-client/sys/auth/email/reg/info`, {
        ...params
    });
};

// 用户绑定银行卡
export const userBank = (params = {}) => {
    return request.post(`/center-client/sys/user/user/bank`, {
        ...params
    });
};

// 用户详情
export const getInfo = (params = {}) => {
    return request.post(`/center-client/sys/user/get/info`, {
        ...params
    });
};

// 手机号密码登录
export const phoneLogin = (params = {}) => {
    // =/sys/auth/phone/login  改 /sys/auth/new/phone/login
    return request.post(`/center-client/sys/auth/new/phone/login`, {
        ...params
    });
};

// 更换设备登录
export const phoneLoginCheck = (params = {}) => {
    return request.post(`/center-client/sys/auth/login/check`, {
        ...params
    });
};

export const emailLoginCheck = (params = {}) => {
    return request.post(`/center-client/sys/auth/email/login/check`, {
        ...params
    });
};

// 获取用户设备信息
export const getUserDevices = (params) => {
    return request.post(`/center-client/sys/user/user/devices`, {
        ...params
    });
};

// 邮箱密码登录
export const emailLogin = (params = {}) => {
    return request.post(`/center-client/sys/auth/new/email/login`, {
        ...params
    });
};

// 邮箱-是否注册
export const emailIsRegiste = (params = {}) => {
    return request.post(`/center-client/sys/user/email/isRegiste`, {
        ...params
    });
};

// 获取邮箱验证码
export const sendEmailVcode = (params = {}) => {
    return request.post(`/order/email/config/send/vcode`, {
        ...params
    });
};

// 邮箱注册-验证码校验
export const codeValidateEmail = (params = {}) => {
    return request.post(`/center-client/sys/auth/email/reg/codeValidate`, {
        ...params
    });
};

/*
    忘记密码
*/
// 忘记密码-邮箱密码重置
export const resetEmailPwd = (params = {}) => {
    return request.post(`/center-client/sys/auth/reset/email/pwd`, {
        ...params
    });
};

// 忘记密码-手机密码重置
export const resetPhonePwd = (params = {}) => {
    return request.post(`/center-client/sys/auth/reset/pwd`, {
        ...params
    });
};


/*
    系统设置
*/
// 黑名单列表 
export const blacklist = (params = {}) => {
    return request.post(`/center-client/sys/user/reject/list`, {
        ...params
    });
};

// 设置-取消 黑名单
export const userReject = (params = {}) => {
    return request.post(`/center-client/sys/user/reject`, {
        ...params
    });
};

// 系统通知 
export const getSystemLetter = (params = {}) => {
    return request.post(`/center-client/message/getSystemLetter`, {
        ...params
    });
};

// 系统公告 
export const getNotice = (params = {}) => {
    return request.post(`/center-client/message/getNotice`, {
        ...params
    });
};


// 获取用户私信列表
export const getUserLetter = (params = {}) => {
    return request.post(`/center-client/message/getUserLetter`, {
        ...params
    });
};

// 所有私信
export const allLetterList = (params = {}) => {
    return request.post(`/center-client/live/allLetter/list`, {
        ...params
    });
};

// 获取用户私信详情
export const getUserLetterInfo = (params = {}) => {
    return request.post(`/center-client/message/getUserLetterInfo`, {
        ...params
    });
};

// 用户私信
export const liveLetter = (params = {}) => {
    return request.post(`/center-client/live/letter`, {
        ...params
    });
};

// 获取未读消息数
export const getUnreadNum = (params = {}) => {
    return request.post(`/center-client/message/getUnreadNum`, {
        ...params
    });
};

// 更新公告为已读
export const changeNoticeRead = (params = {}) => {
    return request.post(`/center-client/message/changeNoticeRead`, {
        ...params
    });
};

// 通过letterId更新消息状态
export const changeLetterStatusById = (params = {}) => {
    return request.post(`/center-client/message/changeLetterStatusById`, {
        ...params
    });
};

// 通过otherUid更新消息状态
export const changeLetterStatusByOtherUid = (params = {}) => {
    return request.post(`/center-client/message/changeLetterStatusByOtherUid`, {
        ...params
    });
};
/*
    个人中心
*/
// 我的关注列表
export const followList = (params = {}) => {
    return request.post(`/center-client/sys/user/follow/list`, {
        ...params
    });
};

// 我的粉丝列表
export const fansList = (params = {}) => {
    return request.post(`/center-client/sys/user/fans/list`, {
        ...params
    });
};

// 一键回收
export const backAllCoin = (params = {}) => {
    return request.post(`/center-client/center/game/backAllCoin`, {
        ...params
    });
};


// 查询余额
export const getBalance = (params = {}) => {
    return request.post(`/center-client/center/game/getBalance`, {
        ...params
    });
};

// 购买座驾
export const buyCar = (params = {}) => {
    return request.post(`/center-client/user/prop/buyCar`, {
        ...params
    });
};

// 获取用户座驾信息
export const propCar = (params = {}) => {
    return request.post(`/center-client/user/prop/car`, {
        ...params
    });
};

// 设置直播间展示座驾
export const propSetShowCar = (params = {}) => {
    return request.post(`/center-client/user/prop/setShowCar`, {
        ...params
    });
};


// 查询玩家流水 不必传参数，后台通过token直接获取当前用户
export const statement = (params = {}) => {
    return request.post(`/center-client/user/statement`, {
        ...params
    });
};

// 修改用户信息
export const modifyInfo = (params = {}) => {
    return request.post(`/center-client/sys/user/modify/user/info`, {
        ...params
    });
};

// 购买Vip
export const buyVip = (params = {}) => {
    return request.post(`/live-recreation/vip/buyVip`, {
        ...params
    });
};

// 获取用户贵族信息
export const vipInfo = (params = {}) => {
    return request.post(`/center-client/sys/user/get/vip/info`, {
        ...params
    });
};

// 续费Vip
export const reNewVip = (params = {}) => {
    return request.post(`/live-recreation/vip/reNewVip`, {
        ...params
    });
};

// 升级Vip
export const upVip = (params = {}) => {
    return request.post(`/live-recreation/vip/upVip`, {
        ...params
    });
};

// 设置-取消隐身
export const vipHide = (params = {}) => {
    return request.post(`/live-recreation/vip/hide`, {
        ...params
    });
};

// 绑定手机号码
export const bindUserMobile = (params = {}) => {
    return request.post(`/center-client/sys/user/user/mobile`, {
        ...params
    });
};

//获取用户资金
export const getuserBalance = (params = {}) => {
    return request.post(`/center-client/assets/get/balance`, {
        ...params
    });
};

//获取行为图像验证码 
export const configCaptcha = (params = {}) => {
    return request.post(`/order/sms/config/captcha`, {
        ...params
    });
};



/*
    充值
*/
// 支付列表
export const payList = () => {
    return request.get(`/order/pay/config/payList`);
};

// 银行卡通道列表 
export const getBankList = (params = {}) => {
    return request.post(`/order/pay/bank/list1`, {
        ...params
    });
};

// 银行卡支付
export const bankOrder = (params = {}) => {
    return request.post(`/order/pay/config/bankOrder`, {
        ...params
    });
};


// 转账
export const transfer = (params = {}) => {
    return request.post(`/center-client/center/game/transfer`, {
        ...params
    });
};


// usdt支付 
export const usdtOrder = (params = {}) => {
    return request.post(`/order/pay/config/usdtOrder`, {
        ...params
    });
};

/*
    提现
*/
// 提现记录列表
export const withdrawList = (params = {}) => {
    return request.post(`/center-client/user/withdraw/list`, {
        ...params
    });
};

// 获取用户切换指定银行卡信息 
export const bankSelected = (params = {}) => {
    return request.post(`/center-client/sys/user/user/bank/selected`, {
        ...params
    });
};

// 银行卡相关验证码（包含网易验证回调二次验证）
export const sendCashCode = (params = {}) => {
    return request.post(`/order/sms/config/bank/sendCashCode`, {
        ...params
    });
};

// 用户绑定银行卡
export const bindBank = (params = {}) => {
    return request.post(`/center-client/sys/user/user/bank`, {
        ...params
    });
};

// 查询用户银行卡
export const bankInfo = (params = {}) => {
    return request.post(`/center-client/sys/user/bankList/Info`, {
        ...params
    });
};

//用户提现
export const withdraw = (params = {}) => {
    return request.post(`/center-client/user/withdraw`, {
        ...params
    });
};

// 切换银行卡
export const switchBank = (params = {}) => {
    return request.post(`/center-client/sys/user/user/switchBank`, {
        ...params
    });
};


/*
    游戏中心
*/
// 获取标签跟标签下的游戏
export const getLabelsAndGameList = (id) => {
    return request.get(`/center-client/center/game/getLabelsAndGameList?id=${id}`);
};

// 添加-取消游戏收藏
export const addGameCollection = (params = {}) => {
    return request.post(`/center-client/center/game/addGameCollection`, {
        ...params
    });
};

/*
    首页
*/
// 火箭位列表
export const rocketlist = (params = {}) => {
    return request.post(`/live-client/live/rocketlist`, {
        ...params
    });
};

//推荐开播列表
export const recommendLiveList = (params = {}) => {
    return request.post(`/live-client/live/recommendLiveList`, {
        ...params
    });
};

// 开播列表
export const tagList = (params = {}) => {
    return request.post(`/live-client/live/new/4231/1529/list`, {
        ...params
    });
};

//推荐列表
export const gameConfig = (uid) => {
    return request.get(`/center-client/center/game/gameConfig?uid=${uid}`);
};

// 左侧菜单栏
export const gamePlatforms = (uid , label=5 ) => {
    return request.get(`/center-client/center/game/gamePlatforms?label=${label}&uid=${uid}`);
};

// 右侧游戏列表
export const gameLabel = (categoryId) => {
    return request.get(`/center-client/center/game/getLabel?categoryId=${categoryId}`);
};

// 右侧游戏列表二级
export const gameLabelGameLists = gamePlatform => {
    return request.get(`/center-client/center/game/getLabelGameLists?gamePlatform=${gamePlatform}`);
};

// 开始游戏
export const startGame = (params = {}) => {
    // /center-client/center/game/startGame
    return request.post(`/center-client/center/game/new/startGame`, {
        ...params
    });
};

// 榜单详情
export const rankDetail = (params = {}) => {
    return request.post(`/fuse-client/rank/rank/detail`, {
        ...params
    });
};

// 关注-取关
export const liveFollow = (params = {}) => {
    return request.post(`/center-client/live/follow`, {
        ...params
    });
};

// 搜索用户
export const userFind = (params = {}) => {
    return request.post(`/center-client/sys/user/find`, {
        ...params
    });
};


// 等级
// export const userLevel = (uid) => {
//     return request.get(`/center-client/user/level/${uid}`);
// };

// usdt列表 
// export const usdtList = (params = {}) => {
//     return request.post(`/config-client/base/usdtList`, {
//         ...params
//     });
// };

// 货币币种 
// export const currtryUnitList = (params = {}) => {
//     return request.post(`/config-client/configCountry/currtryUnitList`, {
//         ...params
//     });
// };

// 语言接口
// export const languageList = (params = {}) => {
//     return request.post(`/config-clientconfigCountry/languageList`, {
//         ...params
//     });
// };

// 是否开启绑定银行卡
// export const isBindBank = (params = {}) => {
//     return request.post(`/config-client/base/isBindBank`, {
//         ...params
//     });
// };

// 是否开启提现银行卡
// export const isWithdrawBank = (params = {}) => {
//     return request.post(`/config-client/base/isWithdrawBank`, {
//         ...params
//     });
// };

// 注册
// export const register = (params = {}) => {
//     // return request.post(`/center-client/sys/auth/phone/reg/info`, {
//     //     ...params
//     // });
//     return request.post(`/center-client/sys/auth/register/info`, {
//         ...params
//     });
// };

// 登录
// export const login = (params = {}) => {
//     return request.post(`/center-client/sys/auth/user/login`, {
//         ...params
//     });
// };







// export const gameList = gamePlatform => {
//     return request.get(`/center-client/center/game/gameList?gamePlatform=${gamePlatform}`);
// };

//修改登录密码
export const updatePwd = (params = {}) => {
    return request.post(`/center-client/sys/auth/update/pwd`, {
        ...params
    });
};

//忘记取款密码-取款密码设置 => 取款密码
export const cashPassword = (params = {}) => {
    return request.post(`/center-client/sys/auth/reset/cashPassword`, {
        ...params
    });
};

//忘记取款密码-取款密码设置 => 取款密码
export const updateCashPassword = (params = {}) => {
    return request.post(`/center-client/sys/auth/update/cashPassword`, {
        ...params
    });
};

// 用户绑定邮件
// export const bindUserEmail = (params = {}) => {
//     return request.post(`/center-client/sys/user/user/email`, {
//         ...params
//     });
// };




// 银行卡通道列表 
// export const bankList = (params = {}) => {
//     return request.post(`/order/pay/config/bank/list`, {
//         ...params
//     });
// };

// 银行卡相关验证码
// export const sendCashCode = (params = {}) => {
//     return request.post(`/order/sms/config/cash/sendCashCode`, {
//         ...params
//     });
// };


// 获取手机验证码
export const sendVcode = (params = {}) => {
    return request.post(`/order/sms/config/send/vcode`, {
        ...params
    });
};


// 充值卡支付
export const cardOrder = (params = {}) => {
    return request.post(`/order/pay/config/cardOrder`, {
        ...params
    });
};

// 三方支付
export const thirdOrder = (params = {}) => {
    return request.post(`/order/pay/config/thirdOrder`, {
        ...params
    });
};

export const bankHistory = (params = {}) => {
    return request.post(`/order/pay/config/new/bank/history`, {
        ...params
    });
};

//用户资产详情记录
export const assetRecord = (params = {}) => {
    return request.post(`/center-client/sys/user/asset/record`, {
        ...params
    });
};

// 获取查询资产名称类型
export const getAssetType = (params = {}) => {
    return request.post(`/center-client/sys/user/asset/getAssetType`, {
        ...params
    });
};


// 各种余额以及分享人数(总收益,充值返利收益,邀请好友收益)
export const userIndex = (id) => {
    return request.get(`/promotion-client/user/index/${id}`);
};

// 邀请记录 
export const shareLog = (params = {}) => {
    return request.post(`/promotion-client/user/share/log`, {
        ...params
    });
};

// 兑换金币
export const userExchange = (params = {}) => {
    return request.post(`/promotion-client/user/exchange`, {
        ...params
    });
};

// 获取所有彩票最近一期开奖结果
export const getAllLotteryLatestResultNew = (params = {}) => {
    return request.post(`/lottery-client/lottery/getAllLotteryLatestResultNew`, {
        ...params
    });
};


// 获得彩票赔率
export const getLotteryPower = (params = {}) => {
    return request.post(`/lottery-client/lottery/getLotteryPower`, {
        ...params
    });
};

// 获取历史开奖记录
export const getLotteryResultHistoryByName = (params = {}) => {
    return request.post(`/lottery-client/lottery/getLotteryResultHistoryByName`, {
        ...params
    });
};

export const getHistorLottery = (params = {}) => {
    return request.post(`/lottery-client/lottery/getHistorLottery`, {
        ...params
    });
};

// 获取历史开奖记录（个人）
export const getBetHistorByUid = (params = {}) => {
    return request.post(`/lottery-client/lottery/getBetHistorByUid`, {
        ...params
    });
};

// 彩票投注
export const lotteryBet = (params = {}) => {
    return request.post(`/lottery-client/lottery/lotteryBet`, {
        ...params
    });
};

// 获得彩票期号
export const getissue = (params = {}) => {
    return request.post(`/lottery-client/lottery/getissue/newList`, {
        ...params
    });
};

// 获取所有配置意见反馈类型
export const exposeConfig = () => {
    return request.get(`/center-client/expose/config`);
};

// 提交用户反馈
// export const exposeAdd = (params = {}) => {
//     return request.post(`/center-client/expose/add`, {
//         ...params
//     });
// };

// 账户是否注册
// export const authIsRegister = (params = {}) => {
//     return request.post(`/center-client/sys/auth/isRegister`, {
//         ...params
//     });
// };

// 忘记密码-密码重置
// export const resetPwd = (params = {}) => {
//     return request.post(`/center-client/sys/auth/reset/pwd`, {
//         ...params
//     });
// };

// 服务条款相关接口
export const termsConfig = (id) => {
    return request.get(`/center-client/terms/config?id=${id}`);
};

// 活动类型
// export const userActivityType = () => {
//     return request.get(`/config-client/base/userActivity/type`);
// };


//  下注记录列表
export const getLotteryDetailsHistory = (params = {}) => {
    return request.post(`/lottery-client/lottery/getLotteryDetailsHistory`, {
        ...params
    });
};

//获取用户彩票游戏统计记录 
export const getLotteryTypeHistory = (params = {}) => {
    return request.post(`/lottery-client/lottery/getLotteryTypeHistory`, {
        ...params
    });
};


// 获取我的消息
export const getNoticeList = (uid) => {
    return request.get(`/center-client/notice/list/${uid}`);
};

// 标记已读
export const noticeMsg = (params = {}) => {
    return request.post(`/center-client/notice/action/msg`, {
        ...params
    });
};

//  获取首页三方游戏标签
export const getGameLists = (params) => {
    return request.get(`/center-client/center/game/getGameLists`, {
        ...params
    });
};

// 获取首页三方子游戏
export const getGames = (id) => {
    return request.get(`/center-client/center/game/getGames?id=${id}`);
};

// 获取分类下的子游戏
// export const getTypeGameLists = (id) => {
//     return request.get(`/center-client/center/game/getTypeGameLists?id=${id}`);
// };

// 获取邮箱后缀列表
export const emailSuffix = (params = {}) => {
    return request.post(`/config-client/base/emailSuffix`, {
        ...params
    });
};


// 申请成为代理
export const joinProxy = (params = {}) => {
    return request.post(`/proxy-client/proxy/apply`, {
        ...params
    });
};



// 获取首页精彩游戏
export const getPopularGame = (id) => {
    return request.get(`/center-client/center/game/getPopularGame?id=${id}`);
};

// 弹窗公告接口
// export const noticeList = (id) => {
//     return request.get(`/config-client/config/app/noticeList`);
// };


// 刷新 
export const refreshKey = (params = {}) => {
    return request.post(`/center-client/sys/auth/refresh/key`, {
        ...params
    });
};

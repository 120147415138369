<template>
  <div>
    <!-- 投注记录 -->
    <van-action-sheet class="weightModel" @close="colsePopup" v-model:show="isShowRecord" :closeable="false" :title="$t('lottery.betRecord')">
        <van-list
            class="vanList"
            v-model="loading"
            :finished="finished"
            style="height:calc(70vh - 2rem);overflow:scroll;"
            :offset="20" 
            :immediate-check="check" 
            :finish-text="`${$t('lottery.noMore')}`"
            @load="onLoad" 
            >
            <div class="recordList">
                <div v-show="recordList.length > 0">
                    <div v-for="(item, index) in recordList" :key="`${index}--recordList`" @click="goDetail(item)" class="recordItem flexBetween">
                        <div class="recordItemItem1">
                            <p>{{getTime(item.createTime)}}</p>
                            <p>{{item.nickName}}</p>
                        </div>
                        <div class="recordItemItem2">
                            <p>{{ $t('lottery.detail.betAmount') }}</p>
                            <p>{{ numFormat(item.betAmount) }}</p>
                        </div>

                        <div class="flexCenterBL">
                            <div v-show="item.awardStatus == 0">
                                {{ $t('lottery.status.wait') }}
                            </div>
                            <img v-show="item.awardStatus == 1" src="../../assets/img/lottery/lose.png" />
                            <img v-show="item.awardStatus == 2" src="../../assets/img/lottery/win.png" />
                            <img v-show="item.awardStatus == 3" src="../../assets/img/lottery/tie.png" />
                        </div>
                    </div>

                    <div v-show="finished" class="showAllTip">{{$t('lottery.showAll')}}</div>
                </div>

                <div v-if="recordList.length == 0" class="noData">
                    <img src="../../assets/img/record/noData.png" />
                    <p>{{$t('lottery.newBetRecrod')}}</p>
                </div>
            </div>
        </van-list>
    </van-action-sheet>
  </div>
</template>

<script>
import { getBetHistorByUid } from "@/api/index";
import { mapState } from 'vuex';
import moment from "moment";
export default {
    name: "liveRoomLotteryRecord",
    data() {
        return {
            isShowRecord: false,
            statusList: [  // 状态列表
                {
                    name: this.$t('lottery.status.all'),
                    type: -1
                },
                {
                    name: this.$t('lottery.status.wait'),
                    type: 0
                },
                {
                    name: this.$t('lottery.status.lose'),
                    type: 1
                },
                {
                    name: this.$t('lottery.status.win'),
                    type: 2
                },
                {
                    name: this.$t('lottery.status.tie'),
                    type: 3
                }
            ],
            finished: true, //是否已加载完
            loading: false, //下拉加载更多加载中动画
            check: false, //是否开始就检查
            statusActive: 0, 
            recordList: [],
            page: 0
        }
    },
    computed: {
        ...mapState(['member']),
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isShowRecord = true;
        }, 10)

        this.getLotteryResultHistory();
    },
    methods: {
        colsePopup() {
            this.isShowRecord = false;
            let that = this;
            setTimeout(() => {
                that.$emit('colsePopup', 'showLotteryRecord')
            }, 1000)
        },
        goDetail(item) {
            sessionStorage.setItem('orderDetail', JSON.stringify(item));
            this.$emit('openPopup', 'showLotteryBetDetail');
        },
        getTime(val) {
          if (val) {
            return moment(val).format('HH:mm DD-MM-YYYY');
          }
          return "";
        },
        // 获取投注记录
        getLotteryResultHistory() {
            let betPramas = {
                betStatus: this.statusList[this.statusActive].type
            }
            this.$store.commit("setState", { betPramas });
            let params = {
                page: this.page,
                pageSize: 10,
                uid: this.member.uid
            }
            if (this.statusList[this.statusActive].type != -1) {
                params.status = this.statusList[this.statusActive].type ; 
            }

            if (!this.page) {
                this.recordList = [];
            }

            this.finished = true;
            getBetHistorByUid(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (!params.page) {
                        this.recordList = res.data;
                    } else {
                        if (res.data.length > 0) {
                            let arr = JSON.parse(JSON.stringify(this.recordList));
                            arr = [...arr, ...res.data];
                            this.recordList = arr;
                        }
                    }

                    if (res.data.length == 10) {
                        this.finished = false;
                    }

                    this.isShowRecord = true;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
            });
        },
         // 加载
        onLoad() {
            if (!this.finished) {
                this.loading = true;
                this.page ++;
                this.getLotteryResultHistory();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.recordList {
    padding: 0.2rem 0;
    color: #333;

    .recordItem {
        border-bottom: 1px solid #ebeff1;
        padding: 0.25rem 0.28rem 0.14rem;
        font-size: 0.28rem;
        
        &>div {
            border-right: 1px solid #c5c5c5;
            flex: 1;

            &:last-child {
                border-right: none;
            }

            p:first-child {
                margin-bottom: 0.16rem;
            }
        }

        .recordItemItem1 {
            p {
                &:first-child {
                    font-size: 0.2rem;
                }
            }
        }

        .recordItemItem2 {
            text-align: center;

            p:last-child {
                color: #000;
                font-weight: bold;
            }
        }

        img {
            width: 1.56rem;
            height: 0.84rem;
        }
    }

    .showAllTip {
        margin: 0.42rem auto;
        font-size: 0.2rem;
        color: #626872;
        text-align: center;
    }
}

</style>
import { mapState } from 'vuex';
import SVGA from "svgaplayerweb";
import { pkNewConfig, pkStatus } from "@/api/live";
import { liveFollow } from "@/api/index";
import interLive from '@/pages/mixin/interRoom';
import md5 from 'js-md5';
import utils from "@/utils/index";
const pkEvent = {
    mixins: [interLive],
    data() {
        return {
            pkRule: "", // pk规则
            isShowPkRule: false, //pk 规则弹框是否展示
            pkTimer: null, //pk 时间容器
            pkTime: '00:00',
            // pkData: {}, //PK中的相关数据
            pkPlayer: null,
            pkParser: null
        }
    },
    computed: {
        ...mapState([
          'pkDataDetail',
          'TCPlayer',
          "liveInfo"
        ]),
        leftPkTeamList() {
            let pkTeamList = this.pkDataDetail && this.pkDataDetail.pkTeamList ? this.pkDataDetail.pkTeamList : [];
            return pkTeamList.filter(item => {
                return item.sortNum == 1 || item.sortNum == 3
            });
            
        },
        rightPkTeamList() {
            let pkTeamList = this.pkDataDetail && this.pkDataDetail.pkTeamList ? this.pkDataDetail.pkTeamList : []

            return pkTeamList.filter(item => {
                return item.sortNum == 2 || item.sortNum == 4
             });
        },
        leftProgress() {
            let num = 50;
            if (this.pkDataDetail && this.pkDataDetail.teamType && this.leftPkTeamList[0] && this.rightPkTeamList[0]) {
                let leftTeamScore = this.leftPkTeamList[0].teamScore ? this.leftPkTeamList[0].teamScore : 0,
                    rightTeam = this.rightPkTeamList.filter(item => {
                        return item.teamId != this.leftPkTeamList[0].teamId;
                    }),
                    rightTeamScore = rightTeam.length > 0 && rightTeam[0].teamScore ? rightTeam[0].teamScore : 0;
                
                if (leftTeamScore || rightTeamScore) {
                    num = (leftTeamScore*1 /(leftTeamScore + rightTeamScore)*1) * 100;

                    if (num < 15) {
                        num = 15;
                    }
        
                    if (num > 85) {
                        num = 85;
                    }
                }
            }
            return num;
        },
        rightProgress() {
            let num = 50;

            if (this.leftProgress) {
                num = 100 - this.leftProgress;
            }
              
            return num;
        },
        leftProgressNum() {
            let num = 0;
            if (this.leftPkTeamList[0] && this.leftPkTeamList[0].teamScore ) {
                num = this.leftPkTeamList[0].teamScore;
            }

            return num;
        },
        rightProgressNum() {
            let num = 0;

            if (this.rightPkTeamList && this.rightPkTeamList.length > 0 && this.leftPkTeamList && this.leftPkTeamList.length > 0) {
                let  rightTeam = this.rightPkTeamList.filter(item => {
                    return item.teamId != this.leftPkTeamList[0].teamId;
                });
    
                if (rightTeam.length > 0 && rightTeam[0].teamScore) {
                    num = rightTeam[0].teamScore;
                }
            }
           
            return num;
        },
        isShowPunishTimer() {
            let flag = false;
            if (this.pkDataDetail && this.pkDataDetail.pkStatus && this.pkDataDetail.pkStatus == 3 && this.leftPkTeamList[0] && this.leftPkTeamList[0].result !== 0 ) {
                if (this.pkDataDetail.teamType && this.leftPkTeamList[0].result != -1) {
                    flag = true;
                }

                if (!this.pkDataDetail.teamType) {
                    flag = true;
                }
            }
            return flag;
        },
        isShowDrawTimer() {
            let flag = false;
            if (this.pkDataDetail && this.pkDataDetail.pkStatus && this.pkDataDetail.pkStatus == 3 && this.leftPkTeamList[0] && this.leftPkTeamList[0].result === 0) {
                flag = true;
            }
            return flag;
        }
    },
    watch: {
        'liveInfo.pking'(val){
            if (val) {
                this.getPkStatus();
                let clientHeight = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight;
                this.TCPlayer && this.TCPlayer.height(clientHeight*0.39);
            } else {
                let clientHeight = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight;
                this.TCPlayer && this.TCPlayer.height(clientHeight);
            }
        },
        'pkDataDetail.pkStatus'(val) {
            if (val == 1) {
                this.pkPlayer && this.pkPlayer.stopAnimation && this.pkPlayer.stopAnimation();
            }

            if (val == 2) {
                let time = this.pkDataDetail.liveForEndSeconds ? parseInt(this.pkDataDetail.liveForEndSeconds) : 0;
                this.goDownPktimer(time);

                if (this.pkDataDetail && this.pkDataDetail.teamType) {
                    this.$nextTick(() => {
                        this.initPkSvg();
                    });
                }
            }

            // 输赢
            if (val == 3) {
                this.pkPlayer && this.pkPlayer.pauseAnimation && this.pkPlayer.pauseAnimation();
                this.pkTime = "00:00"
                let timer = 3,
                 result = null;

                if (this.pkDataDetail && this.pkDataDetail.pkTeamList) {
                    let index = this.pkDataDetail.pkTeamList.findIndex(item => {
                        return item.anchorId == this.liveInfo.anchorId
                    });

                    if (index != -1) {
                        result = this.pkDataDetail.pkTeamList[index].result;
                    }
                }

                if (result) {
                    timer = this.pkDataDetail.punishForEndSeconds ? parseInt(this.pkDataDetail.punishForEndSeconds) : 0;
                }
                
                if (result !== null) {
                    this.goDownPktimer(timer);
                }
                
            }
        },
        leftProgress(val) {
            console.log(val);
        }
    },
    methods: {
        // 倒计时
        goDownPktimer(time) {
            clearInterval(this.pkTimer);
            this.pkTime = this.secondsToTime(time);
            this.pkTimer = setInterval(() => {
              if (time > 0) {
                time --;
              } else {
                clearInterval(this.pkTimer);
              }
              this.pkTime = this.secondsToTime(time);
            }, 1000);
        },
        // 秒格式化
        secondsToTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
          
            return (minutes < 10 ? '0' : '') + minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
        },
        // 关注 pk对方主播
        followOtherSide(item) {
            let params = {
                follow: item.isFollow,
                anchorId: item.anchorId
            }
            this.follow(params);
        },
        // 关注 - 取关
        follow(item) {
            let params = {
                isFollow: item.follow ? false : true,
                targetId: item.anchorId
            }
            liveFollow(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let str = item.follow ? this.$t('my.follow.Unfollowed') : this.$t('my.follow.followed');
                    this.$toast(str);


                    let pkDataDetail = this.pkDataDetail ? JSON.parse(JSON.stringify(this.pkDataDetail)) : {},
                    pkTeamList = pkDataDetail.pkTeamList ? pkDataDetail.pkTeamList : [];
                    
                    let index = pkTeamList.findIndex(pitem => {
                        return pitem.anchorId == item.anchorId
                    });

                    if (index != -1) {
                        pkDataDetail.pkTeamList[index].isFollow = params.isFollow;
                        this.$store.commit("setState", { pkDataDetail });
                    }

                    if (item.anchorId == this.liveInfo.anchorId) {
                        this.anchorDetail.follow = params.isFollow;
                    }

                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 获取PK中的相关数据
        getPkStatus() {
            let uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            params = {
                anchorId: this.liveInfo.anchorId,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid,
            }
            pkStatus(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data.pkStatus) {
                        let pkDataDetail = res.data ? JSON.parse(JSON.stringify(res.data)) : {};
                        if (pkDataDetail.pkStatus == 2) pkDataDetail.punishForEndSeconds = pkDataDetail.punishForEndSeconds - pkDataDetail.liveForEndSeconds;
                        this.$store.commit("setState", { pkDataDetail });
                        
                        if (!this.liveInfo.pking) {
                            let obj = JSON.parse(JSON.stringify(this.liveInfo));
                            obj.pking = true;
                            this.$store.commit("setState", { liveInfo: obj });
                        }
                    } else {
                        let obj = JSON.parse(JSON.stringify(this.liveInfo));
                        obj.pking = false;
                        this.$store.commit("setState", { liveInfo: obj });
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(()=>{})
        },
        // pk时长和惩罚配置
        getPkConfig() {
            if (this.pkRule) return this.isShowPkRule = true;
            pkNewConfig(this.member.uid).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.pkRule = res.data.pkRuleWeb;
                    this.isShowPkRule = true;
                }
            }).catch(()=>{})
        },
        // pk 小拳头 地址目前需要修改
        initPkSvg() {
            let that = this;
            this.pkPlayer = new SVGA.Player('#pkSvg');
            this.pkParser = new SVGA.Parser('#pkSvg');
            this.pkPlayer.loops = 0;
            
            // 必须是服务器地址或者是线上的地址本地是不可以的 会报错
            this.pkParser.load(`https://oss-bw03.qtmlive.com/game/game_1712646080000.svga`, (videoItem) => {
                that.pkPlayer.setVideoItem(videoItem);
                that.pkPlayer.startAnimation();
                that.pkPlayer.clearsAfterStop = true;
            }, () => {
            })
        },
    }
}
export default pkEvent;
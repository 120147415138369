<template>
  <div class="rouletteBox">
    <img class="rouletteBg" src="../../assets/img/live/roulette/roulette.png" />
    <img class="grid" ref="rouletteGrid" :src="require(`../../assets/img/live/roulette/grid-${rouleList.length}.png`)" />
    <img class="gridOne" v-show="isShowLottery" :src="require(`../../assets/img/live/roulette/one-${rouleList.length}.png`)" />
    <img class="rouletteCancel" @click="close" src="../../assets/img/live/roulette/cancel.png" >
    <div class="rouletteGoBox">
      <img class="rouletteGo" v-show="!freeCount" @click="$emit('openPopup', 'isShowBuy')" src="../../assets/img/live/roulette/go.png" />
      <!-- <img class="rouletteGo" v-show="!freeCount" @click="startRoule" src="../../assets/img/live/roulette/goFree.png" /> -->
      <div v-show="freeCount" class="rouletteGoCont" @click="startRoule"> {{ `free ${freeCount} ${$t('liveRoom.roule.second')}` }} </div>
    </div>
    <img class="roulettePointer" src="../../assets/img/live/roulette/pointer.png" />
    <div class="fontBox" ref="rouletteBox">
      <div class="prize-item" v-for="(item, index) in rouleList" :style="prizeStyle(index)" :key="`roulette-${index}`" :class="`grid-${rouleList.length}`">
        <p :class="{'active': isShowLottery && prizeId == index }">{{ item.viewContent }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { userPlayRoule } from "@/api/live";
export default {
  name: "roulette",
  props: ['rouleObj'],
  data() {
    return {
      isRunning: false, // 是否正在抽奖
      baseRunAngle: 360 * 4, // 总共转动角度 至少5圈
      prizeId: 1, // 中奖id 
      lastPrizeId: -1,
      isStart: false,
      rouleTimer: null,
      isShowLottery: false,
      freeCount: 0,
      lastFreeCount: 0,
      rouleList: []
      // rouleList: [
      //   {
      //       "viewContent": "测试2",
      //       "id": 4,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "测试4显示",
      //       "id": 6,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "范围",
      //       "id": 10,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "多大到达",
      //       "id": 13,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "Đang rút 1 lần",
      //       "id": 9,
      //       "freeCount": 1
      //   },
      //   {
      //       "viewContent": "哥哥",
      //       "id": 11,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "2werw er",
      //       "id": 12,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "48 erwr",
      //       "id": 13,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "5efwef er",
      //       "id": 14,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "5efwef er",
      //       "id": 14,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "5efwef er",
      //       "id": 14,
      //       "freeCount": 0
      //   },
      //   {
      //       "viewContent": "5efwef er",
      //       "id": 14,
      //       "freeCount": 0
      //   },
      // ]
    }
  },
  computed: {
    ...mapState(['liveInfo', 'rouleMsg']),
  },
  created() {
    this.freeCount = this.rouleObj.freeCount;
    this.rouleList = this.rouleObj.rouleContent;
  },
  methods: {
    prizeStyle(i) {
      const _degree = 360 / this.rouleList.length
      return `
        width: 66px;
        height: 66px;
        transform: rotate(${_degree * i- 90 }deg);
        transform-origin: 50% 100%;
      `
    },
    startRoule() {
      if(this.isStart || this.isRunning) return;
      this.isStart = true;
      this.isRunning = true;
      this.$emit('colsePopup', 'isShowBuy');

      let fontBox = document.getElementsByClassName('fontBox')[0];
      let grid = document.getElementsByClassName('grid')[0];
      fontBox.style.animation = 'none';
      grid.style.animation = 'none';

      let param = {
        liveId: this.liveInfo.liveId
      }
      this.lastFreeCount = 0;
      userPlayRoule(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          if(res.data && this.rouleList[res.data.winIndex].id == res.data.winId) {
            this.prizeId = res.data.winIndex;
            this.lastFreeCount = res.data.freeCount;
            this.startRun()
          };
        } else {
          if (res.code && res.code == 4003) {
            this.$emit('openPopup', 'showShortBalance');
          } else {
            if (res.msg) this.$toast(res.msg);
          }
          this.isRunning = false;
          this.$refs.rouletteBox.style.animation = 'rouleSpin infinite 5s linear';
          this.$refs.rouletteGrid.style.animation = 'rouleSpin infinite 5s linear';
        }
        this.isStart = false;
      }).catch(() => {
        this.isStart = false
        this.isRunning = false;
        this.$refs.rouletteBox.style.animation = 'rouleSpin infinite 5s linear';
        this.$refs.rouletteGrid.style.animation = 'rouleSpin infinite 5s linear';
      })
    },
    startRouleAnimation() {
      if (!this.isRunning) {
        this.isRunning = true
        // 生成随机数
        let a = Math.random();      
        a = a*9;
        a = Math.ceil(a);
        this.prizeId = a - 1;
        this.startRun()
      }
     
    },
    startRun() {
      console.log('startRun');
      let rotateAngle = 360 / this.rouleList.length,
      totalRunAngle = this.baseRunAngle + 360 - this.prizeId * rotateAngle

      let fontBox = document.getElementsByClassName('fontBox')[0];
      let grid = document.getElementsByClassName('grid')[0];

      fontBox.style = `
        animation: none;
        transform: rotate(${totalRunAngle}deg);
        transition: all 2s ease;
        animation-iteration-count: 1;
      `
      grid.style = `
        animation: none;
        transform: rotate(${totalRunAngle}deg);
        transition: all 2s ease;
        animation-iteration-count: 1;
      `
      clearInterval(this.rouleTimer);
      this.rouleTimer = setInterval(() => {
        this.stopRun();
      }, 2000);
    },
    stopRun(e){
      console.log('stopRun');
      
      let rotateAngle = 360 / this.rouleList.length,
        totalRunAngle = this.baseRunAngle + 360 - this.prizeId * rotateAngle

      this.$refs.rouletteBox.style = `
        animation: none;
        animation-iteration-count: 1;
        transform: rotate(${totalRunAngle - this.baseRunAngle}deg);
      `
      this.$refs.rouletteGrid.style = `
        animation: none;
        animation-iteration-count: 1;
        transform: rotate(${totalRunAngle - this.baseRunAngle}deg);
      `

      this.lastPrizeId = this.prizeId;

      this.freeCount = this.lastFreeCount
      this.isShowLottery = true;
      clearInterval(this.rouleTimer);
      this.rouleTimer = setInterval(() => {
        this.isRunning = false;
        this.isShowLottery = false;
        if(this.rouleMsg && this.rouleMsg.userId) this.$store.dispatch("addChat", this.rouleMsg);
        this.$refs.rouletteBox.style.animation = 'rouleSpin infinite 5s linear';
        this.$refs.rouletteGrid.style.animation = 'rouleSpin infinite 5s linear';
        clearInterval(this.rouleTimer);
      }, 1200);
    },
    close() {
      this.$emit('colsePopup', 'isShowRoule');
    }
  }
}
</script>

<style lang="scss">
.rouletteBox {
  position: relative;

  .rouletteBg {
    width: 236px;
    height: 236px;
  }

  .grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 236px;
    height: 236px;
    animation: rouleSpin infinite 5s linear;
  }

  .gridOne {
    position: absolute;
    top: 0;
    left: 0;
    width: 236px;
    height: 236px;
    z-index: 6;
  }

  .rouletteCancel {
    position: absolute;
    width: 28px;
    top: 40px;
    left: 15px;
    z-index: 8;
  }

  .rouletteGoBox{
    position: absolute;
    top: 88px;
    left: 88px;
    width: 60px;
    z-index: 7;
    font-size: 10px;

    img {
      width: 60px;
    }

    .rouletteGoCont {
      background-image: url('../../assets/img/live/roulette/goFree.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 60px;
      height: 60px;
      line-height: 58px;
      font-size: 10px;
      color: #FFD9A0;
    }
  }

  .roulettePointer {
    position: absolute;
    width: 44px;
    top: 98px;
    left: 0;
    z-index: 8;
  }

  .fontBox {
    position: absolute;
    width: 194px;
    height: 194px;
    top: 23px;
    left: 23px;
    z-index: 6;
    color: #b768d3;
    animation: rouleSpin infinite 5s linear;
  }

  .prize-item {
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    margin: auto;
    padding: 0 0 30px 0;
  }
  .prize-item {
    &.grid-5 p{
      padding-right: 5px;
    }
    &.grid-6 p{
      padding-right: 8px;
    }
    &.grid-7 p{
      padding-right: 11px;
    }
    &.grid-8 p{
      padding-right: 14px;
    }
    &.grid-9 p{
      padding-right: 17px;
    }
    &.grid-10 p{
      padding-right: 20px;
    }
    &.grid-11 p{
      padding-right: 23px;
    }
    &.grid-12 p{
      padding-right: 25px;
    }

    p {
      font-size: 10px;
      text-align: left;
      transform: rotate(90deg);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      box-sizing: border-box;
      width: 64px;

      &.active {
        color: #FFE4B5;
      }
    }
  } 
}


@keyframes rouleSpin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
</style>